export default [
    // {
    //     name: 'backgroundSound',
    //     type: 'audio',
    //     path: '/sounds/background.mp3'
    // },
    {
        name: 'circleModel',
        type: 'gltfModel',
        path: '/models/circle.glb'
    },
    {
        name: 'points1Model',
        type: 'gltfModel',
        path: '/models/points_1.glb'
    },
    {
        name: 'points2Model',
        type: 'gltfModel',
        path: '/models/points_2.glb'
    },
    {
        name: 'points3Model',
        type: 'gltfModel',
        path: '/models/points_3.glb'
    },

    {
        name: 'clouds1Attribute',
        type: 'json',
        path: '/attributes/clouds_1.json'
    },
    {
        name: 'clouds2Attribute',
        type: 'json',
        path: '/attributes/clouds_2.json'
    },
    {
        name: 'clouds3Attribute',
        type: 'json',
        path: '/attributes/clouds_3.json'
    },

    {
        name: 'uvTexture',
        type: 'texture',
        path: '/textures/uv.png'
    },
    {
        name: 'noiseTexture',
        type: 'texture',
        path: '/textures/noise.png'
    },
    {
        name: 'nebula1Texture',
        type: 'texture',
        path: '/textures/nebula1.jpg'
    },
    {
        name: 'nebula2Texture',
        type: 'texture',
        path: '/textures/nebula2.jpg'
    },
    {
        name: 'displacementTexture',
        type: 'texture',
        path: '/textures/displacement.jpg'
    }
]
